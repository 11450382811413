import { HOUSE_STYLES } from '../../constants/filterOptions';

const updateFilterStyles = (currentStyles: string[], styleName: string, value: boolean): string[] => {
  // If a house style is selected, de-select all houses
  if (HOUSE_STYLES.includes(styleName) && value) {
    return [...currentStyles.filter((style) => style !== 'houses'), styleName];
  }

  // If all houses is selected, de-select any house styles
  if (styleName === 'houses' && value) {
    return [...currentStyles.filter((style) => !HOUSE_STYLES.includes(style)), styleName];
  }

  // Add new style
  if (value) {
    return [...currentStyles, styleName];
  }

  // Remove style
  return currentStyles.filter((style) => style !== styleName);
};

export default updateFilterStyles;
