import isEqual from 'lodash/isEqual';
import { Dispatch, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { MY_DRAWN_AREA, MY_LOCATION, MY_TRAVEL_TIME_AREA } from '../constants/search';
import { selectMap } from '../reducers/map';
import { selectProperties } from '../reducers/properties';
import { getNlpData, getNlpDataDebounced, initialState, SearchState, selectSearch } from '../reducers/search';
import { SearchFormNlpResult } from '../types/search';
import { getCategoryFilterOptions } from '../utils/search/category';
import getNlPFilters, { Filters } from '../utils/search/getNlpFilters';
import updateFilterStyles from '../utils/search/updateFilterStyles';

interface GeoPosition {
  coords: {
    latitude: number;
    longitude: number;
  };
}

interface LatLng {
  lat: number | null;
  lng: number | null;
}

type GetLocation = (getter: () => Promise<GeoPosition | null>, clicked: boolean) => Promise<LatLng>;

export interface UseSearchState {
  disableSaveSearch: boolean;
  isSavable: boolean;
  searchText: string;
  setSearchText: Dispatch<string>;
  showRadius: boolean;
  showExpand: boolean;
  nlpData?: SearchFormNlpResult;
  setNlpData: Dispatch<SearchFormNlpResult | undefined>;
  appFilters: SearchState['filters'];
  setAppFilters: Dispatch<SearchState['filters']>;
  nlpFilters: Filters;
  setNlpFilters: Dispatch<Filters>;
  fetchingLocation: boolean;
  getLocation: GetLocation;
  updatePristineText: () => void;
  clearSearchText: () => void;
  handleChangeText: (text: string) => void;
  handleFilterChange: (filter: string) => (value: any) => void;
  handleStyleChange: (filter: string) => (value: boolean) => void;
  handleClearAttribute: (filter: string) => void;
  handleClearFilters: () => void;
}

/*
 * Local state hook used on search screen for mobile and website
 * --------
 * This hook takes a copy of state from the search reducer for use in a component.
 * This allows us to present a search modal without any changes being committed to the store unless the user hits submit
 * Use case example: if a user opens a search modal, makes changes and then cancels. The next time they open the modal,
 * they shouldn't see changes from the previous instance.
 */

const useSearchState = (extraNlpParams?: string): UseSearchState => {
  const properties = useSelector(selectProperties);
  const map = useSelector(selectMap);
  const search = useSelector(selectSearch);
  const searchMounted = useRef(false);
  const nlpMounted = useRef(false);
  const [appFilters, setAppFilters] = useState({ ...search.filters });
  const [nlpData, setNlpData] = useState<SearchFormNlpResult | undefined>();
  const [nlpFilters, setNlpFilters] = useState<Filters>({});
  const [searchText, setSearchText] = useState(search.text);
  const [fetchingLocation, setFetchingLocation] = useState(false);
  const showExpand = !!nlpData?.phrase.search.allowExpand;
  const showRadius = !showExpand && !!(nlpData?.phrase.currentLocation || nlpData?.phrase.search.allowRadius);
  const searchFilters = useRef({ ...appFilters });
  const isSavable = !!(
    (properties.data?.savable && properties.data?.results?.length > 0) ||
    (map.savable && map.markers && map.markers.length > 0)
  );
  const disableSaveSearch =
    properties.loading || !isEqual(searchFilters.current, appFilters) || search.text !== searchText;

  // nlpFilters passed 2nd to allow them overwrite any filters set via inputs
  const filters = { ...appFilters, ...nlpFilters };

  const clearLatLng = () => {
    // clear lng&lng so that updatePristineText does not override new text with MY_LOCATION
    setAppFilters({
      ...appFilters,
      lat: null,
      lng: null,
    });
  };

  const handleChangeText = (text: string) => {
    setSearchText(text);
    // fetch nlpData whenever a user updates search text
    getNlpDataDebounced(text, appFilters, setNlpData, extraNlpParams);
    clearLatLng();
  };

  const clearSearchText = () => {
    setSearchText('');
    setNlpData(undefined);
    getNlpDataDebounced('', appFilters, setNlpData, extraNlpParams);
    clearLatLng();
  };

  const updatePristineText = (filter?: string) => {
    // When a user changes an input filter we have to make sure nlp text is removed from the search text
    if (nlpData?.phrase.asTypedPristine !== undefined && filter !== 'sortOrder') {
      if (searchText.includes(MY_DRAWN_AREA)) {
        setSearchText(MY_DRAWN_AREA);
      } else if (searchText.includes(MY_TRAVEL_TIME_AREA)) {
        setSearchText(MY_TRAVEL_TIME_AREA);
      } else if (filters.lat) {
        setSearchText(MY_LOCATION);
      } else {
        setSearchText(nlpData?.phrase.asTypedPristine);
      }

      setNlpFilters({});
    }
  };

  const handleFilterChange = (filter: string) => (value: any) => {
    updatePristineText(filter);

    if (filter === 'category') {
      const { category, saleType } = getCategoryFilterOptions(value);

      if (filters.saleType !== saleType) {
        // Clear price options if sale type was changed
        setAppFilters({
          ...filters,
          minPrice: '',
          maxPrice: '',
          category,
          saleType,
        });
      } else {
        setAppFilters({
          ...filters,
          category,
          saleType,
        });
      }

      return;
    }

    if (filter === 'garage' || filter === 'largeGarage') {
      const largeGarage = filter === 'largeGarage' ? value : false;
      const garage = filter === 'garage' ? value : false;

      setAppFilters({
        ...filters,
        largeGarage,
        garage,
      });
    } else if (filter.includes('excludeTags-')) {
      let excludeTags: string[] = filters.excludeTags ? [...filters.excludeTags] : [];
      const tag = filter.replace('excludeTags-', '');
      if (value) excludeTags.push(tag);
      else excludeTags = excludeTags.filter((t) => t !== tag);

      setAppFilters({
        ...filters,
        excludeTags,
      });
    } else if (filter.includes('tags-')) {
      let tags: string[] = filters.tags ? [...filters.tags] : [];
      const tag = filter.replace('tags-', '');
      if (value) tags.push(tag);
      else tags = tags.filter((t) => t !== tag);

      setAppFilters({
        ...filters,
        tags,
      });
    } else {
      setAppFilters({
        ...filters,
        [filter]: value,
      });
    }
  };

  const handleStyleChange = (filter: string) => (value: boolean) => {
    updatePristineText();

    const styles = updateFilterStyles(filters.styles, filter, value);

    setAppFilters({
      ...filters,
      styles,
    });
  };

  const handleClearAttribute = (filterValue: string) => {
    // clear additional attribute, e.g. garage
    updatePristineText();

    const filter = filters.filter.filter((row) => row.filterValue !== filterValue);

    setAppFilters({
      ...filters,
      filter,
    });
  };

  const handleClearFilters = () => {
    updatePristineText();
    setAppFilters({ ...initialState.filters, category: filters.category, saleType: filters.saleType });
  };

  const updateNlpFilters = () => {
    // after nlpData is updated, extract an object we can use in state
    const newFilters = getNlPFilters(nlpData?.params, nlpData?.filterItems);

    setNlpFilters({ ...newFilters.nlpFilters });
  };

  const getLocation: GetLocation = async (getLatLng, clicked) => {
    const nlp = await getNlpData(clicked ? MY_LOCATION : searchText, appFilters, setNlpData, extraNlpParams);

    if (nlp?.phrase.currentLocation && appFilters.lat && appFilters.lng) {
      return { lat: filters.lat, lng: filters.lng };
    }

    if (nlp?.phrase.currentLocation) {
      setFetchingLocation(true);
      const position = await getLatLng();

      setFetchingLocation(false);

      if (position) {
        if (clicked) setSearchText(MY_LOCATION);
        setAppFilters({ ...appFilters, lat: position.coords.latitude, lng: position.coords.longitude });
        return { lat: position.coords.latitude, lng: position.coords.longitude };
      }

      // If location fails, reset nlp data in order to clear radius option
      await getNlpData(searchText, appFilters, setNlpData, extraNlpParams);
    }

    setAppFilters({ ...appFilters, lat: null, lng: null });
    return { lat: null, lng: null };
  };

  useEffect(updateNlpFilters, [nlpData]);

  useEffect(() => {
    setSearchText(search.text);
  }, [search.text]);

  useEffect(() => {
    if (!search.filters.epc && search.filters.includePotentialEpc) {
      handleFilterChange('includePotentialEpc')(false);
    }
  }, [search.filters.epc]);

  useEffect(() => {
    // on web, this ensures when history forward/back is used, the search state is updated
    if (searchMounted.current && typeof window !== 'undefined') {
      setAppFilters({ ...search.filters });
    }

    searchFilters.current = { ...search.filters };
    searchMounted.current = true;
  }, [search.filters]);

  useEffect(() => {
    // on web, this ensures when history forward/back is used, the search state is updated
    if (nlpMounted.current && typeof window !== 'undefined') {
      setSearchText(search.text);
    }

    nlpMounted.current = true;
  }, [search.nlpData]);

  useEffect(() => {
    if (!process?.env?.JEST_WORKER_ID) {
      getNlpDataDebounced(searchText, appFilters, setNlpData, extraNlpParams);
    }
  }, [appFilters.category, appFilters.saleType, appFilters.polyIds, searchText]);

  useEffect(() => {
    if (!showRadius && nlpData !== undefined && appFilters.radius) {
      setAppFilters({ ...appFilters, radius: '' });
    }
  }, [showRadius, nlpData]);

  return {
    appFilters,
    disableSaveSearch,
    isSavable,
    setAppFilters,
    showRadius,
    nlpData,
    setNlpData,
    nlpFilters,
    setNlpFilters,
    searchText,
    setSearchText,
    fetchingLocation,
    getLocation,
    updatePristineText,
    clearSearchText,
    handleChangeText,
    handleFilterChange,
    handleStyleChange,
    handleClearAttribute,
    handleClearFilters,
    showExpand,
  };
};

export default useSearchState;
