/* eslint-disable no-undef */
/* eslint-disable no-alert */

const getCurrentLocation = (): Promise<GeolocationPosition | null> => {
  return new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve(position);
      },
      () => {
        resolve(null);
        alert('There was an error getting your location. Check your browser or system settings.');
      },
      {
        timeout: 10000,
      },
    );
  });
};

export default getCurrentLocation;
