import { useState, useEffect } from 'react';

interface useMediaQueryProps {
  width?: number;
  query?: string;
}

const useMediaQuery = ({ width, query }: useMediaQueryProps) => {
  const [targetReached, setTargetReached] = useState(false);
  const updateTarget = (e: MediaQueryListEvent) => setTargetReached(e.matches);

  useEffect(() => {
    const media = window?.matchMedia && window.matchMedia(query || `(max-width: ${width}px)`);

    if (media)
      try {
        // Chrome & Firefox
        media.addEventListener('change', updateTarget);
      } catch (_) {
        if (media.addListener) media.addListener(updateTarget);
      }

    // Check on mount (callback is not called until a change occurs)
    if (media?.matches) {
      setTargetReached(true);
    }

    return () => {
      if (media)
        try {
          // Chrome & Firefox
          media.removeEventListener('change', updateTarget);
        } catch (_) {
          if (media.removeListener) media.removeListener(updateTarget);
        }
    };
  }, [width, query]);

  return targetReached;
};

export default useMediaQuery;
