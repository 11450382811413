import { CATEGORY_OPTIONS } from '../../constants/filterOptions';
import { SearchState } from '../../reducers/search';
import capitalize from '../capitalize';

const categoryRegex = /(ForSale|ForRent)/;

// Returns category option used in category select input in FilterModal
export const getCategoryOption = (filters: SearchState['filters']) => {
  return `${filters.category}For${capitalize(filters.saleType)}`;
};

// Returns category and saleType from combined value used in FilterModal
export const getCategoryFilterOptions = (value: string) => {
  const [category, saleSuffix] = value.split(categoryRegex);
  const saleType = saleSuffix.replace('For', '').toLowerCase();

  return { category, saleType };
};

export const getCategoryAdName = (category: string | undefined, saleType: string) => {
  if (category === 'residential' && saleType === 'rent') return 'property-for-rent';
  if (category === 'residential' && saleType === 'sale') return 'property-for-sale';
  if (category === 'newhomes') return 'new-developments';
  if (category === 'land') return 'land-and-sites';

  return category || 'undefined';
};

export const getPriceSuffix = (filters: SearchState['filters'], suffix: string, fullSuffix: string) => {
  if (filters.category === 'holidayhomes') {
    return [`pw ${suffix}`, ` per week ${fullSuffix}`];
  }

  if (filters.saleType === 'rent') {
    return [`pm ${suffix}`, ` per month ${fullSuffix}`];
  }

  return [` ${suffix}`, ` ${fullSuffix}`];
};

// Returns text representation of selected category and sale type
export const getCategoryString = (filters: SearchState['filters']) => {
  const value = getCategoryOption(filters);
  const category = CATEGORY_OPTIONS.find((option) => option.value === value);

  return category ? category.label : '';
};
