import { SavedSearch, SearchFormNlpResult } from '../../types/search';
import capitalize from '../capitalize';

export interface Suggestion {
  image?: string;
  heading?: string;
  text: string;
  subText: string;
  id: string;
  paramValue?: string;
  searchParams?: SavedSearch;
  url?: string;
  canonicalIndex: number;
}

export interface SectionItem {
  data: Suggestion[];
  key: string;
}

const formatKey = (term?: string) => {
  if (!term) {
    return '';
  }

  if (term === 'COUNTRY') return 'Countries';

  return `${capitalize(term.replace(/_/g, ' '))}s`;
};

const groupTokensByType = (data: SearchFormNlpResult, disableSavedSearches?: boolean) => {
  let canonicalIndex = 0;
  const sections: SectionItem[] = [];

  if (data.suggestions?.length) {
    data.suggestions.forEach((token) => {
      const { searchTerm } = token;

      if (
        searchTerm &&
        searchTerm.termType !== 'ACCOUNT' &&
        searchTerm.termType !== 'ACCOUNT_GROUP' &&
        searchTerm.termType !== 'DEVELOPER'
      ) {
        const index = sections.findIndex((section) => {
          return section.key === formatKey(token.searchTerm?.termType);
        });

        canonicalIndex += 1;

        const item: Suggestion = {
          id: String(searchTerm.id),
          text: searchTerm.uniqueName,
          subText: '',
          canonicalIndex,
        };

        if (index >= 0) {
          sections[index].data.push(item);
        } else {
          sections.push({
            data: [item],
            key: formatKey(searchTerm.termType),
          });
        }
      }
    });
  }

  if (data.addressSuggestions?.length) {
    const section: SectionItem = {
      key: 'Properties',
      data: [],
    };

    data.addressSuggestions.forEach((address) => {
      canonicalIndex += 1;

      section.data.push({
        id: String(address.id),
        text: address.address,
        subText: address.priceText,
        image: address.thumbnailUrl,
        paramValue: String(address.id),
        url: address.path,
        canonicalIndex,
      });
    });

    sections.push(section);
  }

  if (data.accountSuggestions?.length) {
    const agents: SectionItem = {
      key: 'Estate Agents',
      data: [],
    };

    const developers: SectionItem = {
      key: 'Developers',
      data: [],
    };

    data.accountSuggestions.forEach((account) => {
      canonicalIndex += 1;
      const mAccount = {
        id: account.id,
        text: account.organisation,
        image: account.logo?.standard,
        subText: '',
        canonicalIndex,
      };

      if (account.agent) {
        agents.data.push(mAccount);
      } else {
        developers.data.push(mAccount);
      }
    });

    if (agents.data.length > 0) sections.push(agents);
    if (developers.data.length > 0) sections.push(developers);
  }

  if (data.savedSearches?.length && !disableSavedSearches) {
    const section: SectionItem = {
      key: 'Saved Searches',
      data: [],
    };

    data.savedSearches.forEach((savedSearch) => {
      canonicalIndex += 1;

      section.data.push({
        id: String(savedSearch.search.id),
        heading: savedSearch.search.name,
        text: savedSearch.description,
        subText: '',
        searchParams: savedSearch,
        canonicalIndex,
      });
    });

    sections.push(section);
  }

  return sections;
};

export default groupTokensByType;
